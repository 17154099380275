
import React from "react"
import TagList from "../components/tagList"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

const PostExcerpt = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  
  return (
    <article key={post.fields.slug}>
      <header>
        <h3
          style={{
            marginBottom: rhythm(0.25),
            marginTop: rhythm(1.5),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={`/posts${post.fields.slug}`}>
            {title}
          </Link>
        </h3>
        <small style={{ display: `block`, paddingBottom: rhythm(0.20) }}>
          {post.frontmatter.date}
        </small>
      </header>
      <section>
        <div>
          {post.frontmatter.description || post.excerpt}
          {post.frontmatter.tags !== undefined && 
            post.frontmatter.tags.length > 0 &&
            <small 
              className="excerpt-tag-list"
              style={{
                paddingTop: `5px`,
              }}
            >
              <TagList tags={post.frontmatter.tags} />
            </small>}
        </div>
      </section>
    </article>
  )
}

export default PostExcerpt
