import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostExcerpt from "../components/postExcerpt"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { Link } from 'gatsby';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <div style={{paddingBottom: rhythm(1.5)}}>
        <h1 style={{ marginTop: rhythm(1.5) }}>
          Recent Posts
        </h1>
        {posts.map(({ node, index }) => {
          return <PostExcerpt post={ node } key={ index } />
        })}
      </div>
      <hr style={{ marginBottom: rhythm(1) }} />
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            marginLeft: 0,
            marginBottom: rhythm(0.5)
          }}
        >
          <li></li>
          <li>
            <Link to={`/archive/`} rel="next">
              Archive →
            </Link>
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "\\/blog/"}, frontmatter: {tags: {ne: "reference"}}}
      limit: 10
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
